import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/ProfilesScreen.PNG';

function Profiles() {
    return (
        <CarouselItem
            logo={logo}
            header="Invite your Shooters"
            body="Invite team members to download the app, keep their individual scores, track their progress, and participate with their squads."
        />
    );
}

export default Profiles;
