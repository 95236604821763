import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/EventDataScreen.PNG';

function TeamData() {
    return (
        <CarouselItem
            logo={logo}
            header="Individual &amp; Team Stats"
            body="All of the team and individual scoresheets are tracked for each shooter. Those stats are used to calculate a variety of statistics in realtime."
        />
    );
}

export default TeamData;
