import React from 'react';
import { AppBar, Toolbar, Link, GlobalStyles, CssBaseline } from '@mui/material';
import { navbarStyle, navbarLinkStyle } from "../utils/styles";
import logo from '../images/app-logo-light.png';

function NavBar() {
    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <AppBar position="static" sx={navbarStyle}>
                <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <Link href="/" underline='none' sx={navbarLinkStyle}>
                        <img src={logo} alt="logo" style={{ height: "7.5vh" }} />
                    </Link>
                </Toolbar>
            </AppBar>
        </React.Fragment>
     );
}

export default NavBar;
