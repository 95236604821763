import { Box } from '@mui/system';
import { privacyStyle } from "../utils/styles";

function Contact() {
    return (
        <Box sx={privacyStyle}>
            <h1>Contact Us</h1>
            <p>If you have any questions or concerns, contact us by email:</p>
            <p>goshootclays@gmail.com</p>
        </Box>
    );
}

export default Contact;