import androidBadge from '../../images/AndroidStoreBadge.png';
import iosBadge from '../../images/IosStoreBadge.png';
import { Box, } from '@mui/system';
import { Typography } from '@mui/material';
import {
    carouselItemWrapperStyle,
    carouselItemStyle,
    carouselItemSectionsStyle,
    phoneStyle,
    headerStyle,
    bodyStyle,
    footerStyle, 
    appStoreBadge
} from "../../utils/styles";

function CarouselItem(props) {
    const {
        logo,
        header,
        body
    } = props;

    const handleAndroidClick = () => {
        window.open("https://play.google.com/store/apps/details?id=com.gustostudios.goshootmobile");
      };
      
    const handleIOSClick = () => {
        window.open("https://apps.apple.com/app/goshootclays/id1628682525");
      };

    return (
        <Box sx={carouselItemWrapperStyle}>
            <Box sx={carouselItemStyle}>
                <Box sx={carouselItemSectionsStyle}>
                    <Box component="img" src={logo} alt="logo" sx={phoneStyle} />
                </Box>
                <Box sx={carouselItemSectionsStyle}>
                    <Typography sx={headerStyle}>
                        {header}
                    </Typography>
                    <Typography sx={bodyStyle}>
                        {body}
                    </Typography>
                </Box>
            </Box>
            <Box sx={footerStyle}>
                <Box>Download the app:</Box>
                <Box>
                    <Box component="img"
                        src={androidBadge}
                        alt="Android Store Badge"
                        sx={appStoreBadge}
                        onClick={() => handleAndroidClick()} />
                    <Box component="img"
                        src={iosBadge}
                        alt="iOS Store Badge"
                        sx={appStoreBadge}
                        onClick={() => handleIOSClick()} />
                </Box>
            </Box>
        </Box>
    );
}

export default CarouselItem;
