import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/ScoreSheetProgressScreen.PNG';

function ScoreSheet() {
    return (
        <CarouselItem
            logo={logo}
            header="Scoresheets per Event"
            body="Create scoresheets for all categories of an event and never have to keep score on paper again."
        />
    );
}

export default ScoreSheet;
