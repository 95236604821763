import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/EditSquadScreen.PNG';

function Squads() {
    return (
        <CarouselItem
            logo={logo}
            header="Build your Squads"
            body="Creating squads gives you the ability to easily assign shooters to events based on the post they will be shooting at."
        />
    );
}

export default Squads;
