import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/EventsScreen.PNG';

function Events() {
    return (
        <CarouselItem
            logo={logo}
            header="Team Events"
            body="Create events for your team and track the shots of each squad in any number of category based events (Singles, Handicap, or Doubles)."
        />
    );
}

export default Events;
