import { Box, } from '@mui/system';
import { homeStyle, carouselWrapperStyle } from "../utils/styles";
import CreateAccount from './carousel/CreateAccount';
import ScoreSheet from './carousel/ScoreSheet';
import Profiles from './carousel/Profiles';
import TeamData from './carousel/TeamData';
import Squads from './carousel/Squads';
import Events from './carousel/Events';
import Team from './carousel/Team';
import Carousel from 'react-material-ui-carousel';

function Home() {
    return (
        <Box sx={homeStyle}>
            <Box sx={carouselWrapperStyle}>
                <Carousel autoPlay={false} navButtonsAlwaysVisible={true}>
                    <CreateAccount />
                    <Profiles />
                    <Team />
                    <Squads />
                    <Events />
                    <ScoreSheet />
                    <TeamData />
                </Carousel>
            </Box>
        </Box>
    );
}

export default Home;
