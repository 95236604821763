const white = '#FFFFFF';
const green ='#0E400D';
const grey = '#403d39';
const gold = '#FFC300';

export const navbarStyle = {
    bgcolor: grey,
    color: gold,
    borderBottom: '2px solid #FFC300',
    zIndex: 1100,
    minHeight: '10vh'
};

export const navbarLinkStyle = {
    color: white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',    
    mx: 1.5,
    my: 1
};

export const homeStyle = {
    bgcolor: green,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',   
    minHeight: '80vh',
    height: 'auto'
};

export const carouselWrapperStyle = {
    bgcolor: green,
    color: white,
    width: '100%',
    height: 'auto'
};

export const carouselItemWrapperStyle = {
    bgcolor: green,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    padding: '5vh 0 0 0'
}

export const carouselItemStyle = (theme) => ({
    bgcolor: green,
    color: white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },      
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      }
});

export const carouselItemSectionsStyle = (theme) => ({
    height: 'auto',

    [theme.breakpoints.up('lg')]: {
        padding: '0 10vh'
      },      
    [theme.breakpoints.down('lg')]: {
        padding: '0'
      }
});

export const phoneStyle = (theme) => ({
    [theme.breakpoints.up('md')]: {
        height: '60vh'
      },      
    [theme.breakpoints.down('md')]: {
        height: '40vh'
      }    
});

export const appStoreBadge = (theme) => ({
    marginLeft: '8px',

    [theme.breakpoints.up('md')]: {
        width: '120px'
      },      
    [theme.breakpoints.down('md')]: {
        width: '90px'
      },

    "&:hover": {
      cursor: 'pointer'
    }
});

export const headerStyle = (theme) => ({
    marginBottom: '16px',
    
    [theme.breakpoints.up('md')]: {
        width: '60vh',
        fontSize: '34px'
      },      
    [theme.breakpoints.down('md')]: {
        margin: 'auto',
        width: '40vh',
        fontSize: '22px'
      }  
});

export const bodyStyle = (theme) => ({
    height: 'auto',
    
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        width: '60vh'
      },      
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        margin: 'auto',
        width: '50vh'
      },      
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        margin: 'auto',
        width: '40vh'
      } 
});

export const footerStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginTop: '16px',

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      },      
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
});

export const privacyStyle = {
    bgcolor: green,
    color: white,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    minHeight: '80vh',
    padding: '0 10vh 10vh 10vh'
};

export const siteFooterStyle = {
    bgcolor: grey,
    color: white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '2px solid #FFC300',
    padding: '0 5vh',
    minHeight: '10vh'
};

export const footerSocialStyle = {
  color: white,
  fontSize: '12px',
  mx: 1.5,
  my: 1
}

export const footerLinkStyle = {
    color: white,
    fontSize: '12px',
    mx: 1.5,
    my: 1
};

const exportPackage = {
    navbarStyle,
    navbarLinkStyle,
    homeStyle,
    carouselWrapperStyle,
    carouselItemStyle,
    carouselItemSectionsStyle,
    phoneStyle,
    appStoreBadge,
    headerStyle,
    bodyStyle,
    footerStyle,
    privacyStyle,
    siteFooterStyle,
    footerLinkStyle,
    footerSocialStyle
};

export default exportPackage;