import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/LoginScreen.PNG';

function CreateAccount() {
    return (
        <CarouselItem
            logo={logo}
            header="Create an Account"
            body="Create a team, invite your shooters, create your squads and track their progress through events. All the while, tracking statistics for your team and individual shooters."
        />
    );
}

export default CreateAccount;
