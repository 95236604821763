import { Facebook, Twitter} from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import { Box } from '@mui/system';
import {
    siteFooterStyle,
    footerLinkStyle,
    footerSocialStyle
} from "../utils/styles";

function Footer() {
    
    const handleTwitterClick = () => {
        window.open("https://twitter.com/goshootclays");
      };
      
    const handleFacebookClick = () => {
        window.open("https://www.facebook.com/GoShootClays");
      };

    return (
        <Box sx={siteFooterStyle}>
            <nav>
                <IconButton
                    sx={footerSocialStyle}
                    alt="GoShootClays Facebook Page"
                    onClick={() => handleFacebookClick()}>
                    <Facebook />
                </IconButton>
                <IconButton
                    sx={footerSocialStyle}
                    alt="GoShootClays Twitter Page"
                    onClick={() => handleTwitterClick()}>
                    <Twitter />
                </IconButton>
            </nav>
            <nav>
                <Link href="/privacy" underline='none' sx={footerLinkStyle}>
                    Privacy
                </Link>
                <Link href="/contact" underline='none' sx={footerLinkStyle}>
                    Contact Us
                </Link>
            </nav>
        </Box>
    );
}

export default Footer;
