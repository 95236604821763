import CarouselItem from './CarouselItem';
import logo from '../../images/carousel/SquadsScreen.PNG';

function Team() {
    return (
        <CarouselItem
            logo={logo}
            header="Manage your Team"
            body="Separate your shooters into their squads, add them to upcoming events, and easily create score sheets to keep their scores."
        />
    );
}

export default Team;
